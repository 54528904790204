<template>
  <v-container id="training-plans" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="trainingPlansFiltered"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :search="search"
      sort-by="id"
      :sort-desc="true"
      :no-data-text="str['training_plans_empty']"
      :no-results-text="str['training_plans_empty']"
      class="elevation-1"
      @click:row="openTrainingPlan"
      @update:page="(value) => (currentPage = value)"
      @update:items-per-page="(value) => (tableItemsPerPage = value)"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-btn v-if="editPermission" color="success" dark class="mb-2" @click="newTraininPlan()">
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
        <v-toolbar v-if="showPlaceAndGender || showLevel || showDuration" flat class="toolbar-adjust">
          <v-row>
            <v-col
              v-if="showPlaceAndGender && trngPlanPlaces && trngPlanPlaces.length"
              :cols="12"
              :lg="showDuration ? 3 : 4"
              :md="showDuration ? 3 : 4"
              :sm="12"
            >
              <v-autocomplete
                v-model="filters.place"
                :label="str['place']"
                item-text="label"
                item-value="value"
                :items="trngPlanPlaces"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
            <v-col
              v-if="showPlaceAndGender && trngPlanGenders && trngPlanGenders.length"
              :cols="12"
              :lg="showDuration ? 3 : 4"
              :md="showDuration ? 3 : 4"
              :sm="12"
            >
              <v-autocomplete
                v-model="filters.gender"
                :label="str['gender']"
                item-text="label"
                item-value="value"
                :items="trngPlanGenders"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showLevel" :cols="12" :lg="showDuration ? 3 : 4" :md="showDuration ? 3 : 4" :sm="12">
              <v-autocomplete
                v-model="filters.level"
                :label="str['level']"
                item-text="label"
                item-value="value"
                :items="trngPlanLevels"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showDuration" :cols="12" :lg="3" :md="3" :sm="12">
              <v-autocomplete
                v-model="filters.duration"
                :label="str['duration']"
                item-text="label"
                item-value="value"
                :items="trngPlanDurations"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showFilterTotalTrains" :cols="12" :lg="3" :md="3" :sm="12">
              <v-autocomplete
                v-model="filters.total_trains"
                :label="str['total_trains']"
                item-text="label"
                item-value="value"
                :items="trngPlanTotalTrains"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.proposal="{ item }">
        {{ item.proposal ? str['yes'] : str['no'] }}
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" color="teal lighten-2" @click.stop="duplicateItem(item)">mdi-content-duplicate</v-icon>
        <v-icon small class="mr-0" color="error" @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const editPermission = Utils.hasPermission('base_trng_plans_edit')
    const headers = [
      { text: window.strings['id'], value: 'id' },
      { text: window.strings['name'], value: 'name', align: 'center' },
    ]

    let showProposal = false
    if (config && config.training_plans && config.training_plans.show_proposal) {
      showProposal = true
    }

    let openWithLink = false
    if (config && config.training_plans && config.training_plans.open_with_link) {
      openWithLink = true
    }

    if (!openWithLink) {
      headers.push({
        text: window.strings['total_trains'],
        value: 'days_count',
        align: 'center',
      })
    }

    if (showProposal) {
      headers.push({
        text: window.strings['monthly_plan'],
        value: 'proposal',
        align: 'center',
      })
    }

    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
      })
    }

    let showPlaceAndGender = false
    if (config && config.training_plans && config.training_plans.show_place_gender) {
      showPlaceAndGender = true
    }

    let showLevel = false
    if (config && config.training_plans && config.training_plans.show_level) {
      showLevel = true
    }

    let showDuration = false
    if (config && config.training_plans && config.training_plans.show_duration) {
      showDuration = true
    }

    let showFilterTotalTrains = false
    if (config && config.training_plans && config.training_plans.show_filter_total_trains) {
      showFilterTotalTrains = true
    }

    let hasPdfTrainingPlan = false
    if (config && config.menu_items && config.menu_items.indexOf('pdf_training_plans_base') > -1) {
      hasPdfTrainingPlan = true
    }

    return {
      str: window.strings,
      user: user,
      trainingPlans: [],
      currentPage: 1,
      tableItemsPerPage: Utils.getTableCache('trainingPlansBase', 'itemsPerPage') || 10,
      search: '',
      editPermission: editPermission,
      headers: headers,
      openWithLink: openWithLink,
      showProposal: showProposal,
      showPlaceAndGender: showPlaceAndGender,
      showLevel: showLevel,
      showDuration: showDuration,
      showFilterTotalTrains: showFilterTotalTrains,
      hasPdfTrainingPlan: hasPdfTrainingPlan,
      filters: Utils.getTableCache('trainingPlansBase', 'filters') || {
        place: null,
        gender: null,
        level: null,
        duration: null,
        total_trains: null,
      },
      trngPlanPlaces: Utils.getTrngPlanPlaces(),
      trngPlanGenders: Utils.getTrngPlanGenders(),
      trngPlanLevels: Utils.getTrngPlanLevels(),
      trngPlanDurations: Utils.getTrngPlanDurations(),
      trngPlanTotalTrains: Utils.getTrngPlanTotalTrains(),
    }
  },
  computed: {
    trainingPlansFiltered() {
      const self = this
      let trainingPlans = this.trainingPlans
      if (this.showPlaceAndGender) {
        if (this.filters.place) {
          trainingPlans = trainingPlans.filter(function (item) {
            return item.place === self.filters.place
          })
        }
        if (this.filters.gender) {
          trainingPlans = trainingPlans.filter(function (item) {
            return item.gender === self.filters.gender
          })
        }
      }
      if (this.showLevel && this.filters.level) {
        trainingPlans = trainingPlans.filter(function (item) {
          return item.level === self.filters.level
        })
      }
      if (this.showDuration && this.filters.duration) {
        trainingPlans = trainingPlans.filter(function (item) {
          return item.duration === self.filters.duration
        })
      }
      if (this.showFilterTotalTrains && this.filters.total_trains) {
        trainingPlans = trainingPlans.filter(function (item) {
          return item.days_count === parseInt(self.filters.total_trains)
        })
      }
      return trainingPlans
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getTrainingPlans(true)
  },
  beforeDestroy: function () {
    Utils.setTableCache('trainingPlansBase', 'itemsPerPage', this.tableItemsPerPage)
    Utils.setTableCache('trainingPlansBase', 'filters', JSON.parse(JSON.stringify(this.filters)))
    Utils.setTableCache('trainingPlansBase', 'page', this.currentPage)
  },
  methods: {
    getTrainingPlans: function (getCachePage) {
      const self = this
      const payload = {
        fields: ['id', 'name', 'days_count', 'gender', 'place', 'level', 'duration'],
        fast: true,
      }
      if (this.showProposal) {
        payload.fields.push('proposal')
      }
      if (this.hasPdfTrainingPlan) {
        payload.type = 0
      }
      Api.getTrainingPlans(payload, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.trainingPlans = response.data
          if (getCachePage) {
            self.currentPage = Utils.getTableCache('trainingPlansBase', 'page') || self.currentPage
          }
        } else {
          self.trainingPlans = []
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    openTrainingPlan: function (item) {
      const self = this
      this.getPlan(item, function (src) {
        self.$isLoading(false)
        Utils.setStorage('trainingPlan', {
          data: src,
          client: null,
        })
        self.$router.navigate.push({ path: '/home/trainingPlan' })
      })
    },
    newTraininPlan: function () {
      Utils.setStorage('trainingPlan', {
        data: {
          name: '',
          days: [],
        },
        client: null,
      })
      this.$router.navigate.push({ path: '/home/trainingPlan' })
    },
    deleteItem(item) {
      const self = this
      const id = item.id
      const data = {
        id: id,
        client_id: null,
      }

      this.$confirm(
        window.strings['want_delete_training_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteTrainingPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.trainingPlans.length; i++) {
                if (self.trainingPlans[i].id === id) {
                  self.trainingPlans.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        })
        .catch(() => {})
    },
    duplicateItem(item) {
      const self = this

      this.$confirm(
        window.strings['want_duplicate_training_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'info',
        Utils.getAlertOptions(true, false),
      )
        .then(() => {
          self.getPlan(item, function (src) {
            Api.newTrainingPlan(src, function (response) {
              if (response.success) {
                self.getTrainingPlans()
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          })
        })
        .catch(() => {})
    },
    getPlan(plan, callback) {
      const self = this
      this.$isLoading(true)
      Api.getTrainingPlan(
        {
          id: plan.id,
        },
        function (response) {
          if (response.success) {
            callback(response.data[0])
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
  },
}
</script>
